// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-gallery-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-home-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-home-page-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home/HomePage.js" /* webpackChunkName: "component---src-pages-home-home-page-js" */),
  "component---src-pages-home-pages-home-1-jsx": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home/pages/Home1.jsx" /* webpackChunkName: "component---src-pages-home-pages-home-1-jsx" */),
  "component---src-pages-home-pages-home-2-jsx": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home/pages/Home2.jsx" /* webpackChunkName: "component---src-pages-home-pages-home-2-jsx" */),
  "component---src-pages-home-pages-home-3-jsx": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home/pages/Home3.jsx" /* webpackChunkName: "component---src-pages-home-pages-home-3-jsx" */),
  "component---src-pages-home-ui-time-lines-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/home/ui/TimeLines.js" /* webpackChunkName: "component---src-pages-home-ui-time-lines-js" */),
  "component---src-pages-index-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-training-js": () => import("/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/fabio/Documents/projects/site.fabiobiondi.io/site/fabiobiondi-website-04/.cache/data.json")

